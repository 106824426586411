<template>
  <div>
    <h2 class="main-title">
      <!-- {{ $t("message.welcome_to_") }} -->
      <strong>{{ $t("message.welcome_to_prohada_solution") }}</strong>
    </h2>
    <div class="clearfix"></div>
    <br />
    <div class="grid gap grid-4">
      <div class="card purple">
        <h1>27</h1>
        <h2>{{ $t("message.journals") }}</h2>
      </div>
      <div class="card green">
        <h1>19</h1>
        <h2>{{ $t("message.ledger") }}</h2>
      </div>
      <div class="card blue">
        <h1>71</h1>
        <h2>{{ $t("message.clients") }}</h2>
      </div>
      <div class="card gold">
        <h1>23</h1>
        <h2>{{ $t("message.balance_sheets") }}</h2>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
export default {
  name: "Dashboard",
  setup() {
    const store = useStore();
    store.commit("component/SET_LOADER", false);
  },
};
</script>
